import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { AiOutlineClose } from 'react-icons/ai'
import { useGetDisputeDetails } from '../../../hooks/get/useGetDisputeDetails';
import { useAssignDispute } from '../../../hooks/post/useAssignDispute';
import { SettingsBtn } from '../../../components/button';
import TitleText from '../../../components/custom-text/TitleText';
import { PlaceholderCard } from '../../../components/skeleton';
import CustomModal from '../../../components/modal/CustomModal';
import { SimpleSpinner } from '../../../components/loaders';
import { pageURL } from '../../../components/navigation/pagesURL';
import map from '../../../assets/png/google-map.png';
import logo from '../../../assets/png/showingxchange-logo.png';

const DisputeDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { slug } = useParams();
    const navigateTo = useNavigate();
    const getAgentDetails  = useGetDisputeDetails();
    const assignDispute = useAssignDispute();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    const { isPending, error, data } = useQuery({
      queryKey: ['disputeItems'],
      queryFn: () => getAgentDetails('get-billing-dispute-details', slug),
    });

   

    const disputeDetails = data?.data?.data || []; 
    console.log(disputeDetails, 'disputeDetails')

    const handleAssignDispute = async()=>{
      try {
        setIsLoading(true);
        const { status, data } = await assignDispute(slug);
        if(status===200){
          navigateTo(pageURL.listAssignedDisputes)
          toast.success(data?.message);
          handleCloseModal();
        }
      } catch (error) {
        console.log(error)
      }finally{
        setIsLoading(false)
      }
  }

  return (
    <div className='md:h-screen bg-whiteSmoke p-10 font-primary overflow-y-auto'>
      {
        isPending ? (
          <div className="w-11/12 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 m-auto mt-10">
          {Array.from({ length: 8}).map((_, i) => (
          <PlaceholderCard key={i} />
          ))}
      </div>
        ):(
          <>
            <div className='h-80 shadow-md bg-white'>
              <img src={map} alt="" className='h-full w-full object-cover' />
            </div>
            <div className='h-24 flex justify-between items-center bg-white my-5 px-5'>
              <TitleText>Take action</TitleText>
                <SettingsBtn
                    isBorder={false}
                    bgColor="bg-customPrimary"
                    textColor="text-white"
                    width="md:w-2/12"
                    onClick={handleOpenModal}
                >
                  Assign agent
                </SettingsBtn>
            </div>
            <div className='flex flex-col md:flex-row gap-3'>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <ul>
                  <li><strong>property Address: </strong>{disputeDetails?.request_showing?.property_address1}</li>
                  <li><strong>property state: </strong>{disputeDetails?.request_showing?.property_city}</li>
                  <li><strong>request showing date: </strong>{disputeDetails?.request_showing?.requested_showing_date}</li>
                  <li><strong>request showing time: </strong>{disputeDetails?.request_showing?.requested_showing_time}</li>
                </ul>
                <h3 className='my-2 font-bold'>Notes</h3>
                <p>
                  {disputeDetails?.notes}
                </p>
              </div>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <h3>created agent</h3>
                <h3>{disputeDetails?.accepted_agent?.email}</h3>
              </div>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <h3>accepted agent</h3>
                <h3>{disputeDetails?.created_agent?.email}</h3>
              </div>
            </div>
          </>
        )
      }
      <CustomModal
         width="w-[300px] md:w-5/12"
         icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
         iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
         title="Assign dispute to me"
         description="Click on assign to assign this dispute to yourself, then proceed to resolve"
         bgColor="bg-white"
         textColor="text-black"
         closeModal={handleCloseModal}
         isModalOpen={isModalOpen}
      >
        <div className='flex justify-between items-center'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleAssignDispute}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Assign Dispute' }
        </SettingsBtn>
        </div>

      </CustomModal>
    </div>
  )
}

export default DisputeDetails