import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import TitleText from '../custom-text/TitleText';

interface ICustomModalProps {
  width?: string;
  icon?: React.ReactNode;
  iconRemove?: React.ReactNode;
  title?: string;
  description?: string;
  bgColor?: string;
  textColor?: string;
  closeModal?: () => void | undefined;
  isModalOpen?: boolean;
  children?: React.ReactNode;
}
const CustomModal = ({
  width = 'w-1/2',
  icon,
  iconRemove,
  title,
  description,
  bgColor = 'bg-white',
  textColor = 'text-[#000]',
  closeModal,
  isModalOpen,
  children,
}: ICustomModalProps) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal && closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, closeModal]);

  if (!isModalOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal && closeModal();
    }
  };

  // const modalClasses = `${width}  ${textColor} ${bgColor} rounded-md flex`;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick}>
      <motion.div className={`${width} ${bgColor} ${textColor} rounded-md p-6 shadow-lg`}
        initial={{ 
            x: -100, 
            opacity: 0, 
            // scale: 0.8 
        }} 
        animate={{ x: 0, opacity: 1, scale: 1 }} 
        transition={{ type: 'spring', stiffness: 80, damping: 10 }} 
      >
        <div className="flex justify-between items-center mb-4">
          <span>{icon}</span>
          <span onClick={closeModal} className="cursor-pointer">
            {iconRemove}
          </span>
        </div>
        <div className="mb-6">
          <TitleText>{title}</TitleText>
          <p className="mt-3">{description}</p>
        </div>
        <div>{children}</div>
      </motion.div>
    </div>
  );
};

export default CustomModal;
