

import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardIcon } from '@radix-ui/react-icons';
import { GiNotebook } from "react-icons/gi";
import { GrUserAdmin } from "react-icons/gr";
import { PiUsersFourFill } from "react-icons/pi";
import { MdOutlinePayment, MdOutlineRemoveDone } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { FaUserCheck } from "react-icons/fa";
import tokenService from '../../services/token.service';
import { pageURL } from '../navigation/pagesURL';
import logo from '../../assets/png/showingxchange-logo.png';

interface SideBarItem {
  name: string;
  icon: React.ReactNode;
  link: string;
  children?: SideBarItem[];
}

const AdminSideBar: React.FC = () => {
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null); 
  const { user } = tokenService.getUser();

  const sideBarNavItems: SideBarItem[] = useMemo(()=>[
    {
      name: 'Dashboard',
      icon: <DashboardIcon className='w-6 h-6' />,
      link: pageURL.adminDashboard,
    },
    // {
    //   name: 'Operations',
    //   icon: <PiUsersFourFill className='w-6 h-6' />,
    //   link: pageURL.listOperations,
    //   children: [
    //     { name: 'Unassigned Operations', icon: <FaUserCheck className='w-4 h-4' />, link: pageURL.listOperations },
    //     { name: 'Assigned Operations', icon: <MdOutlinePayment className='w-4 h-4' />, link: pageURL.listAssignedOperations },
    //     { name: 'Approved Operations', icon: <MdOutlinePayment className='w-4 h-4' />, link: pageURL.listApprovedOperations },
    //   ],
    // },
    // {
    //   name: 'Billings',
    //   icon: <PiUsersFourFill className='w-6 h-6' />,
    //   link: pageURL.listOperations,
    //   children: [
    //     { name: 'Unassigned Billings', icon: <FaUserCheck className='w-4 h-4' />, link: pageURL.listBillings },
    //     { name: 'Assigned Billings', icon: <MdOutlinePayment className='w-4 h-4' />, link: pageURL.listAssignedBillingUsers },
    //     { name: 'Approved Billings', icon: <MdOutlinePayment className='w-4 h-4' />, link: pageURL.listApprovedBillingUsers },
    //   ],
    // },
    {
      name: 'Staff',
      icon: <PiUsersFourFill className='w-6 h-6' />,
      link: pageURL.listOperations,
      children: [
        { name: 'Operations', icon: <FaUserCheck className='w-4 h-4' />, link: pageURL.listOperations },
        { name: 'Billings', icon: <MdOutlinePayment className='w-4 h-4' />, link: pageURL.listBillings },
      ],
    },
    {
      name: 'Agent Applications',
      icon: <GiNotebook className='w-6 h-6' />,
      link: pageURL.listOperations,
      children: [
        { name: 'Applications', icon: <GrUserAdmin className='w-4 h-4' />, link: pageURL.listAgentApplications },
        { name: 'Assigned Agents', icon: <GrUserAdmin className='w-4 h-4' />, link: pageURL.listAssignedAgents },
      ],
    },
    {
      name: 'Disputes',
      icon: <MdOutlineRemoveDone className='w-6 h-6' />,
      link: pageURL.listOperations,
      children: [
        { name: 'Available Disputes', icon: <GrUserAdmin className='w-4 h-4' />, link: pageURL.listDisputes },
        { name: 'Assigned Disputes', icon: <GrUserAdmin className='w-4 h-4' />, link: pageURL.listAssignedDisputes },
        { name: 'Resolved Disputes', icon: <GrUserAdmin className='w-4 h-4' />, link: pageURL.listResolvedDisputes },
      ],
    },
  ], []);

  const navigate = useNavigate();

  const handleSelectedLink = (link: string) => {
    setSelectedLink(link);
  };

  const handleRedirect = (url: string) => {
    navigate(url);
  };

  const handleLogOut = () => {
    tokenService.removeUser();
    navigate('/');
  };

  const toggleExpanded = (itemName: string) => {
    setExpandedItem(expandedItem === itemName ? null : itemName); 
  };

  const hasAccessToItem = (item: SideBarItem): boolean => {
    if (item.name === 'Agent Applications' && (user.is_admin === true || user.is_operation === true)) return true;
    if (item.name === 'Disputes' && (user.is_admin === true || user.is_billing === true)) return true;
    //if ((item.name === 'Billings' || item.name === 'Operations') && (user.is_admin === true)) return true;
    if ((item.name === 'Staff') && (user.is_admin === true)) return true;
    if (item.name === 'Dashboard') return true; 
    return false; 
  };

  return (
    <div className='h-full flex flex-col text-xsm font-primary font-normal justify-between'>
      <div className='border border-b '>
        <div className='w-10/12 m-auto flex items-center gap-3 p-3'>
          <img src={logo} alt="showingxchange-logo" className='rounded-full h-12 w-12' />
          <div>
            <h3 className='text-xsm font-bold text-customPrimary'>showingxchange</h3>
            <h3 className='text-xxsm font-bold text-customPrimary'>Dashboard</h3>
          </div>
        </div>
      </div>
      <div className='h-full flex flex-col'>
        <ul className='w-10/12 m-auto'>
          {sideBarNavItems.filter(hasAccessToItem).map((item, index) => (
            <li key={index} className={`py-1 px-2 my-1 rounded-md cursor-pointer`}>
              <div className={`flex items-center gap-3 my-1 py-2 px-2 hover:bg-whiteSmoke rounded-sm duration-500 ${selectedLink === item.name ? 'bg-whiteSmoke' : ''}`}
                onClick={() => {
                  handleSelectedLink(item.name);
                  if (!item.children) {
                    handleRedirect(item.link);
                  } else {
                    toggleExpanded(item.name);  
                  }
                }}>
                <span className='text-customSecondary font-bold'>{item.icon}</span>
                {item.name}
              </div>
              {item.children && expandedItem === item.name && ( 
                <ul className='pl-5'>
                  {item.children.map((child, idx) => (
                    <li key={idx} className='py-1'>
                      <div className={`flex items-center gap-3 py-2 px-2 hover:bg-whiteSmoke rounded-sm duration-500 ${selectedLink === child.name ? 'bg-whiteSmoke' : ''}`}
                        onClick={() => {
                          handleSelectedLink(child.name);
                          handleRedirect(child.link);
                        }}>
                        <span className='text-customSecondary font-bold'>{child.icon}</span>
                        {child.name}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className='border border-t p-10'>
        <div className='w-11/12 m-auto flex items-center gap-3'>
          <span className='text-customSecondary font-bold'><AiOutlineLogout /></span>
          <span onClick={handleLogOut} className='cursor-pointer'>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
