import React from 'react';

interface ISettingsBtnProps {
  isBorder?: boolean;
  bgColor?: string;
  textColor?: string;
  width?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const SettingsBtn = ({
  isBorder = false,
  bgColor = 'bg-transparent',
  textColor = 'text-black',
  width = 'w-auto',
  onClick,
  children,
}: ISettingsBtnProps) => {
  const borderClass = isBorder ? `border ${bgColor}` : '';
  const buttonClasses = `${width} ${borderClass} ${bgColor} ${textColor} py-2 px-3 rounded-md capitalize flex items-center justify-center gap-2`;
  return (
    <button onClick={onClick} className={buttonClasses}>
      {children}
    </button>
  );
};

export default SettingsBtn;
