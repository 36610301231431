import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import { AiOutlineClose } from 'react-icons/ai'
import { useGetDisputeDetails } from '../../../hooks/get/useGetDisputeDetails';
import { useAssignDispute } from '../../../hooks/post/useAssignDispute';
import { useResolveDispute } from '../../../hooks/post/useResolveDispute';
import { SettingsBtn } from '../../../components/button';
import TitleText from '../../../components/custom-text/TitleText';
import { PlaceholderCard } from '../../../components/skeleton';
import CustomModal from '../../../components/modal/CustomModal';
import { SimpleSpinner } from '../../../components/loaders';
import { pageURL } from '../../../components/navigation/pagesURL';
import map from '../../../assets/png/google-map.png';
import logo from '../../../assets/png/showingxchange-logo.png';

const AssignedDisputeDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedType, setSelectedType] = useState<string>('');
    const { slug } = useParams();
    const navigateTo = useNavigate();
    const getDisputeDetails  = useGetDisputeDetails();
    const resolveDispute = useResolveDispute();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const [formData, setFormData] = useState({
        billing_comment:'',
        resolved_paid_to: ''
    });
    const agentTypes = ['Creating Agent', 'Accepting Agent'];


    const { isPending, error, data } = useQuery({
      queryKey: ['disputeItems'],
      queryFn: () => getDisputeDetails('get-assigned-dispute', slug),
    });

   

    const disputeDetails = data?.data?.data?.dispute || []; 
    

    const handleChange = (e:any)=>{
        const { name, value } = e.target
        setFormData({...formData, [name]:value })
    }

    const handleSelectType = (type: string)=>{
        setSelectedType(type);
        setFormData(prev => ({
            ...prev, resolved_paid_to: type
        }))
    }


    const handleResolveDispute = async()=>{
        if(!formData?.billing_comment || !formData.resolved_paid_to){
            toast.error('Ensure that you provide a comment and select agent type to proceeed!');
            return
        }
      try {
        setIsLoading(true);
        const { status, data } = await resolveDispute(formData, disputeDetails?.slug);
        if(status===200){
          navigateTo(pageURL.listResolvedDisputes)
          toast.success(data?.message);
          handleCloseModal();
        }
      } catch (error) {
        console.log(error)
      }finally{
        setIsLoading(false)
      }
  }

  return (
    <div className='md:h-screen bg-whiteSmoke p-10 font-primary overflow-y-auto'>
      {
        isPending ? (
          <div className="w-11/12 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-1 m-auto mt-10">
          {Array.from({ length: 8}).map((_, i) => (
          <PlaceholderCard key={i} />
          ))}
      </div>
        ):(
          <>
            <div className='h-80 shadow-md bg-white'>
              <img src={map} alt="" className='h-full w-full object-cover' />
            </div>
            <div className='h-24 flex justify-between items-center bg-white my-5 px-5'>
              <TitleText>Take action</TitleText>
                <SettingsBtn
                    isBorder={false}
                    bgColor="bg-customPrimary"
                    textColor="text-white"
                    width="md:w-2/12"
                    onClick={handleOpenModal}
                >
                  Resolve Dispute
                </SettingsBtn>
            </div>
            <div className='flex flex-col md:flex-row gap-3'>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <ul>
                  <li><strong>property Address: </strong>{disputeDetails?.request_showing?.property_address1}</li>
                  <li><strong>property state: </strong>{disputeDetails?.request_showing?.property_city}</li>
                  <li><strong>request showing date: </strong>{disputeDetails?.request_showing?.requested_showing_date}</li>
                  <li><strong>request showing time: </strong>{disputeDetails?.request_showing?.requested_showing_time}</li>
                </ul>
                <h3 className='my-2 font-bold'>Notes</h3>
                <p>
                  {disputeDetails?.notes}
                </p>
              </div>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <h3>created agent</h3>
                <h3>{disputeDetails?.accepted_agent?.email}</h3>
              </div>
              <div className='md:w-4/12 bg-white h-80 p-3 shadow-md rounded-sm'>
                <h3>accepted agent</h3>
                <h3>{disputeDetails?.created_agent?.email}</h3>
              </div>
            </div>
          </>
        )
      }
      <CustomModal
         width="w-[300px] md:w-5/12"
         icon={<img src={logo} alt='showingxchange logo' width={30} height={30} className='rounded-full border border-customPrimary p-1' />}
         iconRemove={<AiOutlineClose onClick={handleCloseModal} className="w-6 h-6 text-[#667085]" />}
         title="Resolve Dispute"
         description="By clicking on resolve dispute, You agree that all necessary checks has been completed and this dispute can be marked as resolved."
         bgColor="bg-white"
         textColor="text-black"
         closeModal={handleCloseModal}
         isModalOpen={isModalOpen}
      >
        <div>
            <div>
                <label htmlFor="billing_comment" className="font-normal text-sm">Your Comment</label>
                <textarea 
                    className="w-full p-3 rounded-sm mt-2 outline-0 border border-slate-400"
                    value={formData.billing_comment}
                    rows={6}
                    name="billing_comment" 
                    id="billing_comment"
                    onChange={handleChange}
                />
            </div>
            <h3 className='mt-3'>Resolution is in favour of:</h3>
            <div className='my-5'>
                {
                    agentTypes.map((type, i)=>(
                        <div key={i} onClick={()=> handleSelectType(type)} className='my-2'>
                            <h3 className='flex items-center gap-3'>{selectedType === type ? <IoMdRadioButtonOn className='w-6 h-6 text-customPrimary' /> : <IoMdRadioButtonOff className='w-6 h-6 text-customPrimary' />} <span>{type}</span></h3>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className='flex justify-between items-center border-t py-5'>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-whiteSmoke"
            textColor="text-[#000]"
            width="md:w-3/12"
            onClick={handleCloseModal}
        >
          Cancel
        </SettingsBtn>
        <SettingsBtn
            isBorder={false}
            bgColor="bg-customPrimary"
            textColor="text-white"
            width="md:w-3/12"
            onClick={handleResolveDispute}
        >          
          { isLoading ? <SimpleSpinner size={'sm'}/> : 'Resolve Dispute' }
        </SettingsBtn>
        </div>

      </CustomModal>
    </div>
  )
}

export default AssignedDisputeDetails